<template lang="pug">
  <div>

    <nav>
      <ul>
        <li><router-link :to="{ name: 'servidor' }">Servidor</router-link></li>
        <li>
          <router-link :to="{ name: 'margem' }">Margem consignável</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'consignacoes' }">Consignações</router-link>
        </li>
        <li><a href="#">Portabilidades</a></li>
        <li><a href="#">Reservas de cartão</a></li>
        <li><a href="#">Histórico de margem</a></li>
        <li><a href="#">Liberação margem</a></li>
        <li><a href="#">Propostas</a></li>
        <li><a href="#">Propostas Negociações</a></li>
      </ul>
    </nav>

    <transition-router-view />



    <!-- <p>Área que vai exibir a lista de vínculos</p> -->
  </div>
</template>

<script>
export default {
  name: 'DashBoard',
  beforeRouteUpdate(to, from, next) {
    if (this.temMaisDeUmVinculo) {
      next()
    } else {
      if (to.name === 'dashboard') {
        next({ name: 'servidor' })
      }
      next()
    }
  },
  data() {
    return {
      temMaisDeUmVinculo: true,
    }
  },

  created() {
    if (!this.temMaisDeUmVinculo) {
      this.$router.push({ name: 'servidor' })
    }
  },
}
</script>

<style scoped>
nav ul {
  margin: 20px 0px;
  padding: 0px 0px;
}

nav ul li {
  display: inline;
}

nav ul li a {
  margin: 0px 0px;
  padding: 15px 8px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
nav ul li a:hover {
  color: blue;
}

.router-link-active {
  color: blue;
  font-weight: bold;
}
</style>
